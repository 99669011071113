<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.torneos.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursal_id"
                        :placeholder="$t('app.sucursales.single')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.nombre"
                        :placeholder="$t('app.headers.name')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.color') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.color')"
                      rules="required"
                    >
                      <v-color-picker
                        dot-size="25"
                        swatches-max-height="200"
                        v-model="item.color"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.journey_quantity') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.journey_quantity')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="item.cantidad_jornadas"
                        :placeholder="$t('app.headers.journey_quantity')"
                        :error-messages="errors"
                        required
                        type="number"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.registration_fee') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.registration_fee')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="item.costo_inscripcion"
                        :placeholder="$t('app.headers.registration_fee')"
                        :error-messages="errors"
                        required
                        prefix="$"
                        suffix="MXN"
                        type="number"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.deposit') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.deposit')"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="item.fianza"
                        :placeholder="$t('app.headers.deposit')"
                        :error-messages="errors"
                        required
                        prefix="$"
                        suffix="MXN"
                        type="number"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.start_date') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="datePickerModalStart"
                      v-model="datePickerModalStart"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.start_date')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.fecha_inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="item.fecha_inicio"
                        :locale="$store.state.lang"
                        @input="datePickerModalStart = false"
                      />
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.end_date') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="datePickerModalEnd"
                      v-model="datePickerModalEnd"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.end_date')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.fecha_fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="item.fecha_fin"
                        :locale="$store.state.lang"
                        :min="item.fecha_inicio"
                        @input="datePickerModalEnd = false"
                      />
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.game_duration') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.game_duration')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.duracion_juego"
                        :placeholder="$t('app.headers.game_duration')"
                        :error-messages="errors"
                        required
                        suffix="min"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.active') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-switch
                      v-model="item.activo"
                      class="pl-5"
                      :placeholder="$t('app.headers.active')"
                      :label="item.activo ? $t('app.general.yes') : $t('app.general.no')"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="equipos.length > 0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.equipos.name') }}

                    <v-btn
                      small
                      outlined
                      @click="item.equipos.push({equipo_id: null, hora_preferente: null})"
                    >
                      <v-icon left>
                        mdi-plus-circle
                      </v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-for="(equippo, index) in item.equipos"
                      :key="`equipo-${index}`"
                    >
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.equipos.name')"
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="item.equipos[index].equipo_id"
                            :disabled="equipos.length === 0"
                            :placeholder="$t('app.equipos.name')"
                            :items="equipos"
                            item-text="nombre"
                            item-value="id"
                            :error-messages="errors"
                            required
                          >
                            <template v-slot:item="data">
                              <v-list-item-avatar>
                                <img :src="data.item.logotipo">
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-text="data.item.nombre" />
                                <v-list-item-subtitle v-text="data.item.sucursal.nombre" />
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <v-dialog
                          ref="timePickerModal"
                          v-model="timePickerModal"
                          :return-value.sync="item"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('app.headers.description')"
                              rules="required"
                            >
                              <v-text-field
                                v-model="item.equipos[index].hora_preferente"
                                :label="$t('app.headers.time')"
                                prepend-icon="mdi-clock-outline"
                                readonly
                                v-bind="attrs"
                                :error-messages="errors"
                                required
                                v-on="on"
                              />
                            </validation-provider>
                          </template>
                          <v-time-picker
                            v-if="timePickerModal"
                            v-model="item.equipos[index].hora_preferente"
                            full-width
                          >
                            <v-col class="d-flex justify-center">
                              <v-btn
                                text
                                color="primary"
                                @click="timePickerModal = false"
                              >
                                {{ $t('app.buttons.cancel') }}
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="timePickerModal = false"
                              >
                                {{ $t('app.buttons.ok') }}
                              </v-btn>
                            </v-col>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-btn
                          icon
                          @click="item.equipos.splice(index, 1)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'TorneosAdd',
    data () {
      return {
        dialog: false,
        item: {
          nombre: '',
          sucursal_id: null,
          fecha_inicio: new Date().toISOString().substr(0, 10),
          fecha_fin: new Date().toISOString().substr(0, 10),
          cantidad_jornadas: null,
          costo_inscripcion: null,
          fianza: null,
          equipos: [],
          duracion_juego: '',
          color: {
            r: 224,
            g: 224,
            b: 224,
            a: 1,
          },
          activo: false
        },
        minDate: new Date().toISOString().substr(0, 10),
        sucursales: [],
        teams: [],
        datePickerModalStart: false,
        datePickerModalEnd: false,
        timePickerModal: false,
      }
    },
    computed: {
      equipos () {
        return this.teams.filter(team => team.sucursal_id === this.item.sucursal_id)
      },
    },
    mounted () {
      EventBus.$on('torneos-add', async () => {
        this.toggleLoader()
        this.sucursales = await this.getBranches()
        this.teams = await this.getEquipos()

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.post(route('v1/tournament'), this.item)
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.item = {
                    nombre: '',
                    sucursal_id: null,
                    fecha_inicio: new Date().toISOString().substr(0, 10),
                    fecha_fin: new Date().toISOString().substr(0, 10),
                    cantidad_jornadas: null,
                    costo_inscripcion: null,
                    fianza: null,
                    equipos: [],
                    color: {
                      r: 224,
                      g: 224,
                      b: 224,
                      a: 1,
                    },
                    activo: false
                  }

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
